import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import Breadcrumbs from "../components/Breadcrumbs"
// import PostNavigation from "../components/PostNavigation"
import PostCategorys from "../components/posts/PostCategorys"
import RelatedPosts from "../components/RelatedPosts"
import SidebarTrial from "../components/banners/SidebarTrial"
// import ContactUs from "@components/ContactUs"
import Seo from "@components/Seo.js"
import PopupDiscount from "../components/popup/PopupDiscount"
import PopupDeadline from "../components/popup/PopupDeadline"
// import PopupFreeTrial from "../components/popup/PopupFreeTrial"
import BottomBanner from "../components/BottomBanner"
import Sharing from "../components/Sharing"
import CountdownСounterBanner from "./../components/banners/CountdownСounterBanner"
import CountdownСounterBannerSecond from "./../components/banners/CountdownСounterBannerSecond"
import { StructuredDataHelper } from "@helpers/structuredDataHelper.js"
import Loader from "@components/Loader"
import { Suspense, lazy} from "@uploadcare/client-suspense";

const Writers = lazy(() => import('@components/mainpage/Writers'));

const EssayPost = ({pageContext}) => {
    const structuredData = new StructuredDataHelper(pageContext)
    const post = pageContext
    const itemListElements = [
        {
            position: 1,
            name: 'CustomEssayMeister',
            item: 'https://www.customessaymeister.com/'
        },
        {
            position: 2,
            name: '✔️ Free Essays',
            item: 'https://www.customessaymeister.com/sample-essays'
        },
        {
            position: 3,
            name: `⭐️⭐️⭐️${post.post_title}`,
            item: `${post.id}`
        }
    ]
    const pageData = {
        title: post.post_meta_title,
        description: `⭐ ${post.post_meta_description}`,
        keywords: `${post.category_group_title} ${post.category_title}`,
        og: {
            type: 'article'
        },
        micromarking: structuredData.getBreadcrumbs({ itemListElements })
    }
    const steps = [
        {
            name: 'Sample essay',
            path: '/sample-essays'
        },
        {
            name: post.post_slug.replace(/-/g, ' '),
            path: post.url
        }
    ]

    const writersSliderSetting = {
        responsiveViewDesc: 1,
        responsiveViewTablet: 1,
        responsiveViewTablet2: 1,
        responsiveViewMobile: 1,
        animationDuration: 400,
        autoPlayInterval: 5000,
        animationType: 'fadeout',
        autoPlay: true,
        deskTop: false
    }

    return (
        <>
            <Seo {...pageData} />
            <Layout>
                <div className="essay-post__intro page-intro">
                    <div className="wrapper essay-post__intro-wrapper">
                        <Breadcrumbs steps={steps}/>
                    </div>
                </div>
                {/* <CountdownСounterBannerSecond /> */}
                <div className="post__content wrapper">
                    <div className="post__content-wrapper">
                        <h1 className="page-intro__title">{post.post_title}</h1>
                        {/* <p className="essay-post__description">
                            {post.post_meta_description}
                        </p> */}
                        <div className="essay-post__intro-footer">
                            <PostCategorys info={post}/>
                            {/* <div>
                                <span className="essay-post__intro-date">
                                    {new Date(post.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                </span>
                            </div> */}
                        </div>
                        {/* <ContactUs /> */}
                        {/* {post.table_of_contents.length > 0 && <PostNavigation table={post.table_of_contents}/>} */}
                        <div
                            className="content-block"
                            dangerouslySetInnerHTML={{ __html: post.processed_content }}
                        />
                        <Sharing
                            url={`https://www.customessaymeister.com/essay/${post.post_slug}`}
                            title={post.post_meta_title}
                            description={post.post_meta_description}
                            image="https://www.customessaymeister.com/img/og_image.png"
                            tags={["customessaymeister", "writing"]}
                        />
                    </div>
                    <div className="post__content-banners">
                        <div className="banners-wrapper">
                            <SidebarTrial />
                            {/* <Suspense fallback={<Loader/>}>
                                <Writers writersSliderSetting={writersSliderSetting}/>
                            </Suspense> */}
                            <RelatedPosts 
                                posts={post.related}
                                slug={post.post_slug}
                                path="essay"
                                columnTitle={`more in ${post.category_group_title} / ${post.category_title}`} 
                            />
                        </div>
                    </div>
                </div>
                {/* <CountdownСounterBanner /> */}
                <BottomBanner keyword={post.post_keyword_singular} />
                {/* <PopupDiscount timer={45000}/> */}
                {/* <PopupDeadline timer={25000}/> */}
                {/* <PopupFreeTrial timer={5000}/> */}
            </Layout>
        </>
    )
}
export default EssayPost
