import * as React from "react"

const SidebarTrial = () => (
    <section className="trial__section">
        <div className="wrapper">
            <div className="trial__container">
                <div className="trial__content">
                    <span className="title">
                        Get <i>$25 credit</i> to try our service <i>FOR FREE</i>
                    </span>
                    <a
                        id="btnFreeTrialSidebar"
                        href="/free-trial"
                        className="button button-primary"
                    >
                        try for free
                    </a>
                </div>
            </div>
        </div>
    </section>
)

export default SidebarTrial

